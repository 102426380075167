import { ConnectButton } from "@rainbow-me/rainbowkit"
import { useRef } from "react"
import { login } from "../services/AuthService"
export const Header = () => {

  const walletRef = useRef(null)

  const loginFn = async (address) => {

    try {
      const userData = await login(address)
      sessionStorage.setItem("userDetails", JSON.stringify(userData.data.data.login))
    } catch (err) {
      console.log(err)
    }

  }

  return (
    <div class="row align-items-center">
      <div class="col-lg-2 zmovo-logos">
        <div class="zmovo-logo">
          <a href=""><img src="image/Pooltrex-logo.png" alt="Pooltrex" /></a>
        </div>
      </div>
      <div class="col-lg-8 zmovo-menus">
        <div class="main-menu">
          <div class="navigation">
            <div class="menu-container">
              <div id="navigation">
                <ul>
                  <li class="active"><a href="index.html">Home</a></li>
                  <li><a href="#">About</a></li>
                  <li><a href="#">Faqs</a></li>
                  <li><a href="#">Contact</a></li>
                  <li><a href="#">Twitter</a></li>
                  <li><a href="#">Telegram</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 zmovo-t-right">
        <div class="zmovo-header-right text-right">
          <div class="zmovo-login">
            {/* <ConnectButton /> */}

            <ConnectButton.Custom>
              {({
                account,
                chain,
                openConnectModal,
                mounted, connectModalOpen
              }) => {
                try {
                  const connected =
                    mounted &&
                    account &&
                    chain

                  if (connected && account && account.address !== "") {
                    if (walletRef.current != account.address) {
                      walletRef.current = account.address;
                      console.log(account.address)
                      loginFn(account.address);
                    }
                  }

                  return (<div className="nav-item d-flex align-items-center">
                    {connected ? <div className="btn-secondary border-radius-5 btn-md text-white" ref={walletRef}>{account.displayName}</div> : <button type="button" onClick={openConnectModal} className="btn login-btn cw-btn">Connect Wallet</button>}
                  </div>)
                } catch (err) {
                  //console.log("Connected")
                }

              }}
            </ConnectButton.Custom>
          </div>
        </div>
      </div>
    </div>
  )
}
