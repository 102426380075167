import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ThemeContext from "./context/ThemeContext";
import { Provider } from 'react-redux';
import { store } from './store/store';
import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
  getDefaultWallets,
  RainbowKitProvider
} from "@rainbow-me/rainbowkit";
import constants from './jsx/constants';
import "@rainbow-me/rainbowkit/styles.css";
import { bscTestnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from "wagmi/providers/public";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

const AlCHEMY_API_KEY = constants.AlCHEMY_API_KEY;

const root = ReactDOM.createRoot(document.getElementById('root'));


const { provider, chains } = configureChains([bscTestnet], [alchemyProvider({ apiKey: AlCHEMY_API_KEY }), publicProvider(),
]);

const { connectors } = getDefaultWallets({
  appName: "PoolTrex",
  chains
});


const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

const client = new ApolloClient({
  uri: 'http://localhost:8080/api-v2', // Replace with your GraphQL API endpoint
  cache: new InMemoryCache()
});


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeContext>
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
            <ApolloProvider client={client}>
            <ToastContainer />
                <App />
                </ApolloProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </ThemeContext>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
