export const Footer = () =>{
    return(
        <div class="row">
                <div class="col-sm-5 col-lg-6">
                  <div class="zmovo-ft-menu">
                    <ul>
                      <li><a href="#">Terms</a></li>
                      <li><a href="#">Privacy</a></li>
                      <li><a href="#">About</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-7 col-lg-6">
                  <div class="zmovo-ft-copyright">
                    <p>CopyRight 2023 <span class="blue-clr">Pooltrex</span>. All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
    )
}