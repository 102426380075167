import { ethers } from "ethers";
import { notifyError } from "../jsx/components/PluginsMenu/Toastr/Toastr";
import constants from "../jsx/constants";
import usdtAbi from "./ContractABI/usdt-abi.json";

function TransferUSDT(amount, ownerAddress) {
  if (!window.ethereum) {
    notifyError('Please install your metamask wallet');
    return;
  }

  const usdtAddress = '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd'; // Address of the USDT contract
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const usdtContract = new ethers.Contract(usdtAddress, usdtAbi, signer);


  const transfer = async () => {
    try {
      const amountInUSDT = ethers.utils.parseUnits(amount.toString(), 18);
      const tx = await usdtContract.transfer(ownerAddress, amountInUSDT);
      const bnbRes = { hashId: tx.hash, blockExplorer: `${constants.BSC_SCAN_EXPLORER}${tx.hash}` }
      return bnbRes;
    } catch (error) {
      notifyError("Transaction failed. Please try again.");
      return
    }
  }

  return transfer();

}

export default TransferUSDT;
