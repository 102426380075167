import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    MAKEPAYMENT_CONFIRMED_ACTION,
    PROFILE_SUCCESS_ACTION,
    CHECK_COMMENT_ACTION,
    CHECK_PAYMENT_DETAILS_ACTION
} from '../actions/AuthActions';

const initialState = {
    auth: {
        email: '',
        token: '',
        customerId: '',
        tokenExpiration: '',
        refreshToken: '',
        role:''
    },
    profile:{
        firstName : '',
        lastName : '',
        email:'',
        address:'',
    },
    payment:{},
    connectedAccount:{
        address:'',
        displayName:''
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    type:'',
    supportId:'',
    incidentId:''

};

export function AuthReducer(state = initialState, action) {

 
    return state;
}

    
