import { useEffect, useState } from "react";
import { getAllProjects, getSlotDetails, makePaymentPool } from "../services/AuthService";
import TransferBNB from "../web3/TransferBNB";
import TransferBUSD from "../web3/TransferBUSD";
import TransferUSDT from "../web3/TransferUSDT";
import { Button, PopoverBody, Popover } from "react-bootstrap";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import constants from "../jsx/constants";
import { notifyError, notifySuccess } from "../jsx/components/PluginsMenu/Toastr/Toastr";


export const Projects = () => {
  const [slotDetails, setSlotDetails] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [currentProject, setCurrentProject] = useState({
    projectName: '',
    projectDesc: '',
    projectWebsite: '',
    projectTwitter: '',
    projectTelegram: '',
    projectSupply: '',
    projectNetwork: '',
    projectStartTime: '',
    projectEndTime: '',
    projectTotalFund: '',
  });
  const [userDetails, setUserDetails] = useState({
    userId: ''
  });

  const currentTimestamp = Date.now();

  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  const address = constants.OWNER_ADDRESS;

  const getSlots = async (project) => {
    try {
      const userData = await getSlotDetails(project.projectId);
      //console.log(userData.data.data.getSlotDetails[0])
      setSlotDetails(userData.data.data.getSlotDetails)
    } catch (err) {
      console.log(err)
    }
  }
  const getAllProjectsDetails= async  () =>{
    try {
      const projectDetails1 = await getAllProjects();
      if (projectDetails1) {
        const res = projectDetails1.data.data.getAllProjects;
        setProjectDetails(res);
        setCurrentProject(res[0]);
        if (res.length >= 1) {
          getSlots(res[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    getAllProjectsDetails();
    const data = sessionStorage.getItem('userDetails');
    if (data) {
      setUserDetails(data);
    }
    return () => {
    }
  }, []);

  const updateProject = (project) => {
    setCurrentProject(project);
    getSlots(project)
  };



  const makePayment = async (paymentType, slot) => {
    if (userDetails.userId != '') {
      const walletDetails = JSON.parse(localStorage.getItem('wagmi.store'));
      if (walletDetails.state && walletDetails.state.data && walletDetails.state.data.account && walletDetails.state.data.account !== '' ) {
        const data = JSON.parse(sessionStorage.getItem('userDetails'));
        if (data) {
          setUserDetails(data);
        }
        if (paymentType === 'BNB') {
          const status = await TransferBNB(slot.slotPrice, walletDetails.state.data.account);
          console.log(status);
          if(status){
            const payment = await makePaymentPool(slot.projectId,slot.slotId,data.customerId,status.blockExplorer,slot.slotPrice);
            console.log(payment);
            notifySuccess("Payment SuccessFull!");
            getAllProjectsDetails();
          } else{
            //notifyError("User Rejected")
          }
        } else if (paymentType === 'BUSD') {
          const status = await TransferBUSD(slot.slotPrice, walletDetails.state.data.account);
          console.log(status);
          if(status){
            const payment = await makePaymentPool(slot.projectId,slot.slotId,data.customerId,status.blockExplorer,slot.slotPrice);
            console.log(payment);
            notifySuccess("Payment SuccessFull!");
            getAllProjectsDetails();
          } else{
            //notifyError("User Rejected")
          }
          
        } else if (paymentType === 'USDT') {
          const status = await TransferUSDT(slot.slotPrice, walletDetails.state.data.account);
          console.log(status);
          if(status){
            const payment = await makePaymentPool(slot.projectId,slot.slotId,data.customerId,status.blockExplorer,slot.slotPrice);
            console.log(payment);
            notifySuccess("Payment SuccessFull!");
            getAllProjectsDetails();
          } else{
            //notifyError("User Rejected")
          }
        }
      } else {
        openConnectModal();
      }
    } else {
      openConnectModal();
    }
  }


  return (

    <>
      <div className="row mt-4 px-3">
        {projectDetails && projectDetails.map((project, index) => (
          <div className="col-lg-4 col-md-4" onClick={() => updateProject(project)}>
            <div className="zmovo-slider-contents projects">
              <div className="row align-items-center" key={index}>
                <div className="col-lg-6">
                  <div className="zmovo-slide-content">
                    <div className="zmovo-slider-contetn">
                      <a href={project.projectWebsite ? project.projectWebsite : ""} className="orange-clr" target="_blank">
                        {project.projectName}
                      </a>
                      <div className="zmovo-slide-cat">
                        <ul>
                          <li className="two-line-text">{project.projectDesc}</li>
                        </ul>
                      </div>
                      <div className="zmovo-blog-meta mb-4">
                        <ul>
                          <li>
                            <a href={project.projectTelegram ? project.projectTelegram : ""} target="_blank" className="social-links">
                              <span className="fa fa-telegram"></span>Telegram
                            </a>
                          </li>
                          {project.projectTwitter && (
                            <li>
                              <a href={project.projectTwitter} target="_blank" className="social-links">
                                <span className="fa fa-twitter"></span>Twitter
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="zmovo-slider-premium-tag">
                        <a href={project.projectWebsite ? project.projectWebsite : ""} target="_blank" className="btn btn-info btn-md">
                          View Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="project-thumbnail">
                    <a href={project.projectWebsite ? project.projectWebsite : ""} target="_blank">
                      <img
                        src={`image/projects/${project.projectImage}`}
                        className="img-fluid project-logo"
                      />
                    </a>
                  </div>
                </div>
              </div>

             
                {/* {project.startDate > currentTimestamp && project.endDate > currentTimestamp ? (
                  <div className="coming-soon">Coming Soon</div>
                ) : (
                  <a className="video-play-button" href="#">
                    <span className="">Active</span>
                  </a>
                )} */}
                
                {index === 0 ?  <div className="live"> <a className="video-play-button" href="#">
                  <span className="">Active</span> 
                </a></div> :
                  <div className="coming-soon">Coming Soon</div>
                }
              
            </div>
          </div>
        ))}
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="row">
              <div className="col-lg-8 col-12">
                <div className="zmovo-slider-contetn d-flex align-items-center justify-content-center justify-content-lg-start">
                  <a>Total Required <span className="blue-clr"> {currentProject ? currentProject?.projectId : currentProject?.projectId} </span></a>
                </div>
              </div>
              <div className="col-lg-4 col-12 d-flex align-items-center justify-content-center justify-content-lg-end">
                <span className="px-2">Filled:</span> <span className="blue-clr"> {currentProject ? currentProject.projectCurrentFund : ""} </span>
                <span className="px-2">Available:</span> <span className="blue-clr">{currentProject ? currentProject.projectTotalFund - currentProject.projectCurrentFund : ""}</span>
              </div>
            </div>
            <div className="slot-container mt-4">
              {slotDetails && slotDetails.map((slot, index) =>
                <div className="box">
                  <div className="zmovo-counter-items active">
                    <div className="zmovo-counter-number">
                      <span>{slot.slotPrice}</span>
                    </div>
                    <div className="zmovo-counter-text">
                      {slot.slotStatus === 'Filled' && slot.userId === userDetails.userId ? (
                        <div>
                          {slot.userId}
                          <h4 className="booked">Booked</h4>
                          <a href={slot.txnHash} target="_blank" class="transaction-link">Transaction Link</a>
                        </div>
                      ) : slot.slotStatus === 'Filled' ? (
                        <h4 className="booked">Booked</h4>
                      ) : slot.slotStatus === 'Pending' ? (
                        <div>
                          <h4>Pending</h4>
                          <Button color="zmovo-slider-premium-tag m-4" onClick={() => makePayment('BNB', slot)}>Buy with BNB</Button>
                          <Button color="zmovo-slider-premium-tag m-4" onClick={() => makePayment('BUSD', slot)}>Buy with BUSD</Button>
                          <Button color="zmovo-slider-premium-tag m-4" onClick={() => makePayment('USDT', slot)}>Buy with USDT</Button>
                          {/* <Button color="zmovo-slider-premium-tag m-5" onClick={() => makePayment('BUSD', slot)}>Buy with BUSD</Button>
                          <Button color="zmovo-slider-premium-tag m-5" onClick={() => makePayment('USDT', slot)}>Buy with USDT</Button> */}
                        </div>
                      ) : (
                        <p>Buy Slots</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
          <div className="col-lg-4 col-md-5">
            <div className="zmoto-inner-page text-left">
              <div className="zmovo-about-page">
                <div className="zmovo-abouts">
                  <div className="container">
                    <div className="row align-items-start">
                      <div className="col-lg-12">
                        <div className="zmovo-about-content">
                          <div className="zmovo-about-img mb-4">
                            {/* <img src="image/projects/about.jpg" className="img-fluid img-border" alt="" /> */}
                            <div className="project-thumbnail about-prj-content">
                              <a href={currentProject ? currentProject.projectWebsite : ""} target="_blank">
                                {currentProject ? <img
                                  src={`image/projects/${currentProject.projectImage}`}
                                  className="img-fluid project-logo"
                                /> : ""}
                              </a>
                            </div>
                          </div>
                          <p className="mb-4">{currentProject && currentProject.projectDesc}</p>

                          <div className="zmovo-blog-meta mb-4">
                            <ul>
                              <li><a href={currentProject ? currentProject.projectTelegram: ""} target="_blank"
                                className="social-links"><span
                                  className="fa fa-telegram"></span>Telegram</a></li>
                              <li><a href={currentProject ? currentProject.projectTwitter : ""}  target="_blank"
                                className="social-links"><span
                                  className="fa fa-twitter"></span>Twitter</a></li>
                            </ul>
                          </div>

                          <div className="dec-review-meta mb-4">
                            <p><b>Tokenomics Info</b></p>
                            <ul>
                              <li><span>Name <label>:</label></span><a
                                href="">{currentProject? currentProject.projectName : ""}</a></li>
                              <li><span>Supply <label>:</label></span><a
                                href="">{currentProject? currentProject.projectSupply: ""}</a></li>
                              <li><span>Network <label>:</label></span><a href="">{currentProject ? currentProject.projectNetwork: ""}</a></li>
                              <li><span>Coin <label>:</label></span><a
                                href="">{currentProject ? currentProject.projectName: ""}</a></li>
                            </ul>
                          </div>
                          <div className="button text-start">
                            <a href={currentProject ? currentProject.projectWebsite : ""} target="_blank" className="btn btn-radus">View More <span
                              className="fa fa-angle-right"></span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}