// const BASE_URL_WEBSITE = "http://localhost:3001";
// const BASE_URL = "http://localhost:3001/api-v2";
const BASE_URL_WEBSITE = "https://prod.pooltrex.dev3lab.com/";
const BASE_URL = "https://prod.pooltrex.dev3lab.com/api-v2";

const AlCHEMY_API_KEY = "3uNa8ABVEyQrMQ9xSB2K5bK-HIsAZsp6";
const OPEN_EXCHANGE_API_KEY = "bb0330c5bcee4b269cff8b29e9b95ea4";

const BSC_SCAN_URL = 'https://data-seed-prebsc-1-s1.binance.org:8545/';

const BSC_SCAN_EXPLORER = 'https://testnet.bscscan.com/tx/';

const OWNER_ADDRESS = '0xF21e1EfdC2fD4D6f5ff86541E1AFaeA3f5a21B10';


const constants = {
    BASE_URL_WEBSITE,
    BASE_URL,
    AlCHEMY_API_KEY,
    OPEN_EXCHANGE_API_KEY,
    BSC_SCAN_URL,
    BSC_SCAN_EXPLORER,
    OWNER_ADDRESS,
}


export default constants
