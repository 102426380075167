import './App.css';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Projects } from './components/Projects';

function App() {

  return (
    <div className="App">
      <div class="zmovo-main dark-img">
        {/* <!-- Preloader --> */}
        <div class="zmovo-preloader">
          <div class="boxes">
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        {/* <!-- End Preloader --> */}
        <header class="zmovo-header zmovo-header3">
          <div class="container">
            <Header />
          </div>
        </header>
        {/* <!-- SLIDER SECTION --> */}
        <Projects /> 
        <footer class="zmovo-footer-area pt-50">

          <div class="zmovo-footer-buttom">
            <div class="container">
              <Footer />
            </div>
          </div>
        </footer>
        {/* <!-- END FOOTER --> */}
        <div class="to-top" id="back-top">
          <i class="fa fa-angle-up"></i>
        </div>
      </div>
    </div>
  );
}

export default App;
