import { ethers } from "ethers";
import { notifyError } from "../jsx/components/PluginsMenu/Toastr/Toastr";
import constants from "../jsx/constants";

function TransferBNB(amount, ownerAddress) {

  if (!window.ethereum) {
    notifyError('Please install your metamask wallet');
    return;
  }

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const amountInBNB = ethers.utils.parseUnits(amount.toString(), "18");
  // Send the transaction to transfer BNB to the owner
  const transactionObject = {
    to: ownerAddress,
    value: amountInBNB,
    gasLimit: 21000,
    gasPrice: ethers.utils.parseUnits("10", "gwei"),
  };

  const transfer = async () => {
    try {
      const transaction = await signer.sendTransaction(transactionObject);
      const bnbRes = { hashId: transaction.hash, blockExplorer: `${constants.BSC_SCAN_EXPLORER}${transaction.hash}` }
      return bnbRes;
    } catch (error) {
      notifyError("Transaction failed. Please try again.")
      return
    }
  }

  return transfer();

}

export default TransferBNB;
