import { ethers } from "ethers";
import { notifyError } from "../jsx/components/PluginsMenu/Toastr/Toastr";
import constants from "../jsx/constants";
import busdAbi from "./ContractABI/busd-abi.json";

function TransferBUSD(amount, ownerAddress) {
  if (!window.ethereum) {
    notifyError('Please install your metamask wallet');
    return;
  }

  const busdAddress = '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee'; // Address of the BUSD contract
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const usdtContract = new ethers.Contract(busdAddress, busdAbi, signer);

  const transfer = async () => {
    try {
      const amountInUSDT = ethers.utils.parseUnits(amount.toString(), 18);
      const tx = await usdtContract.transfer(ownerAddress, amountInUSDT);
      const bnbRes = { hashId: tx.hash, blockExplorer: `${constants.BSC_SCAN_EXPLORER}${tx.hash}` }
      return bnbRes;
    } catch (error) {
      notifyError("Transaction failed. Please try again.");
      return
    }
  }

  return transfer();
}

export default TransferBUSD;
