import axios from 'axios';
//import swal from "sweetalert";
import { disconnect } from '@wagmi/core';
import constants from '../jsx/constants';
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import { store } from '../store/store';
//import axiosInstance from '../services/AxiosInstance';

const advPostRequest = (requestBody) => {
    const state = store.getState();
    const token = state.auth.auth.token;
    return axios({
        url: constants.BASE_URL,
        method: 'post',
        timeout: 100000,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(requestBody)
    });
}

export function adminLoginService(email, password, role) {
    const postData = {
        email,
        password,
        role
    };
    let req = {
        query: `query AdminLogin($email:String!, $password:String!, $role:String!){
            adminLogin(email:$email, password:$password, role:$role) {
                customerId
                token
                tokenExpiration
                role
            }
          }
          `,
        variables: postData
    }
    return axios({
        url: constants.BASE_URL,
        method: 'post',
        timeout: 10000,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(req)
    }
    );
}

export function login(address) {
    if (address == '')
        return false

    let req = {
        query: `query Login($address:String!){
            login(address:$address) {
                customerId
                token
                tokenExpiration
                role
            }
          }
          `,
        variables: {
            address
        }
    };


    return axios({
        url: constants.BASE_URL,
        method: 'post',
        timeout: 10000,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(req)
    }
    );
}

export function getSlotDetails(projectId){
    if (projectId == '')
        return false

    let req = {
        query: `query getSlotDetails($projectId:String!){
            getSlotDetails(projectId:$projectId) {
                projectId
                slotId
                slotPrice
                slotStatus
                txnHash
            }
          }
          `,
        variables: {
            projectId
        }
    };


    return axios({
        url: constants.BASE_URL,
        method: 'post',
        timeout: 10000,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(req)
    }
    );
}



export function getAllProjects() {
    let requestBody = {
        query: `
        query{
            getAllProjects{
                projectName
                projectDesc
                projectNetwork
                projectStatus
                projectSupply
                projectTelegram
                projectTwitter
                projectWebsite
                startDate
                endDate
                projectId
                projectCurrentFund
                projectTotalFund
                projectImage
            }
            
          }
        `
    }
    return axios({
        url: constants.BASE_URL,
        method: 'post',
        timeout: 100000,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(requestBody)
    }
    );
}

export function makePaymentPool(projectId, slotId, userId, txnHash, slotPrice) {
    let requestBody = {
        query: `
          mutation MakePayment(
            $projectId:String!,
            $slotId:String!,
            $userId:String!,
            $txnHash:String!, 
            $slotPrice:String!  
            ) {
                makePayment(paymentInput:{
                    projectId:$projectId,
                    slotId:$slotId
                    userId:$userId
                    txnHash:$txnHash
                    slotPrice:$slotPrice
                }){
                    projectId
                    slotId
                    slotStatus
                    txnHash
                    userId
                }
          }
        `,
        variables: {
            projectId: projectId,
            slotId: slotId,
            userId: userId,
            txnHash: txnHash,
            slotPrice: slotPrice
        }
    }
    return axios({
        url: constants.BASE_URL,
        method: 'post',
        timeout: 100000,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(requestBody)
    }
    );
}



export function forgotPassword(email, otp, password) {
    let requestBody = {
        query: `
          mutation ForgetPassword(
            $email:String!,
            $otp:String!,
            $newPassword:String!  
            ) {
                forgetPassword(email:$email, otp:$otp, newPassword:$newPassword)
          }
        `,
        variables: {
            email: email,
            otp: otp,
            newPassword: password
        }
    }
    return axios({
        url: constants.BASE_URL,
        method: 'post',
        timeout: 100000,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(requestBody)
    }
    );
}
export function createSupportTicket(supportTicketInput) {
    let req = {
        query: `mutation createSupportTicket(
            $customerId:String!,
            $platform:String!,
            $description:String!,
            $subject:String!
            ) {
                createSupportTicket(supportInput: {
                   customerId:$customerId,
                   platform:$platform,
                   description:$description,
                   subject:$subject
            }){
                supportId
                description
                platform
          
            }
            }
          `,
        variables: supportTicketInput
    }
    return advPostRequest(req);

}

export function createComment(threadInput) {
    let req = {
        query: `mutation createComment(
            $supportId:String!,
            $custId:String!,
            $message:String!
            ) {
                createComment(threadInput: {
                    supportId:$supportId,
                    custId:$custId,
                    message:$message
            })
            }
          `,
        variables: threadInput
    }
    return advPostRequest(req);

}


export function approveIncident(incidentId) {
    let req = {
        query: `mutation approveIncident(
            $incidentId:String!) {
                approveIncident(incidentId:$incidentId){
                    customerId,
                    status
                }
            }`,
        variables: {
            incidentId: incidentId
        }
    }
    return advPostRequest(req);
}

export function runIncident(incidentId) {
    let req = {
        query: `mutation runIncident(
            $incidentId:String!) {
                runIncident(incidentId:$incidentId){
                    customerId,
                    status
                }
            }`,
        variables: {
            incidentId: incidentId
        }
    }
    return advPostRequest(req);
}

export function makePayment(data) {
    let req = {
        query: `mutation MakePayment(
                $customerId:String!,
                $amount:Float!,
                $walletAddress:String!,
                $tokenName:String!,
                $tokenSymbol:String!,
                $transcationHash:String!,  
                $startDate:String!,
                $endDate:String!, 
                $launchpadURL:String!,
                $launchPad:String!,
                $tokenAddress:String!,   
                $position:String!,
                $duration:String!,
                $payWith:String!
                ) {
                    makePayment(paymentInput: {
                             customerId:$customerId,
                             amount:$amount, 
                             tokenName:$tokenName,
                             tokenSymbol:$tokenSymbol,
                             transcationHash:$transcationHash,
                             walletAddress:$walletAddress,
                             startDate:$startDate, 
                             endDate:$endDate,
                             launchpadURL:$launchpadURL, 
                             launchPad:$launchPad,
                             tokenAddress:$tokenAddress,
                             position:$position,
                             duration:$duration,
                             payWith:$payWith
                            })
                }`,
        variables: data
    }
    return advPostRequest(req);
}

//==================
// helper  functions

export function formatError(errorResponse) {
    switch (errorResponse.message) {

        case 'USER_DOES_NOT_EXISTS':
            //swal("Oops", "User doesn't exists", "error");
            return constants.USER_DOES_NOT_EXISTS;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return "";
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.tokenExpiration * 3600 * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(async () => {
        await disconnect();
        dispatch(Logout(navigate));
    }, timer);
}

export async function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        await disconnect();
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if ((todaysDate > expireDate)) {
        dispatch(Logout(navigate));
        await disconnect();
        return;
    }
    if (window.location.pathname == '/admin-login' || window.location.pathname == '/login') {
        dispatch(Logout(navigate));
        await disconnect();
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}
